import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthenticationContext } from '../../_context';
import type { TFillMetadataQueryFunction, THttpMetadataQuery } from '../../_http';
import { Button, Icon, SearchField } from '../../_shared';
import { useGetRoles } from '../_queries';
import RolesTable from './RolesTable';

import './rolesOverview.scss';

const RolesOverview: FC = () => {
  const { profile } = useAuthenticationContext();
  const [rolesQuery, setRolesQuery] = useState<THttpMetadataQuery>({});
  const { data, isLoading, fetchNextPage, refetch } = useGetRoles(rolesQuery);
  const { t } = useTranslation();

  const allRoles = data?.pages.reduce((users, page) => [...users, ...page.data], []);
  const metadata = data?.pages[data.pages.length - 1].meta;

  const setQuery: TFillMetadataQueryFunction = (partialQuery: THttpMetadataQuery) => {
    setRolesQuery(query => ({ ...query, ...partialQuery }));
  };

  useEffect(() => {
    if (rolesQuery?.skip !== 0) {
      fetchNextPage();
    } else {
      refetch();
    }
  }, [setRolesQuery, fetchNextPage, refetch, rolesQuery]);

  return (
    <main className="container roles">
      <div className="header">
        <SearchField query={rolesQuery} setQuery={setQuery} />
          <Button href="create" theme="primary">
              <Icon name="SvgAdd" />
              <span>{t('ROLES.OVERVIEW.CREATE_ROLE')}</span>
          </Button>
      </div>
      <RolesTable data={allRoles} isLoading={isLoading} metadata={metadata} setQuery={setQuery} />
    </main>
  );
};

export default RolesOverview;
