import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Outlet } from 'react-router-dom';

import { SvgLogo } from '../../_assets/svg';

import './unauthorizedLayout.scss';

const UnauthorizedLayout: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="unauthorized-layout">
      <div className="unauthorized-content">
        <SvgLogo />
        <h2 className="unautorized-title">{t('AUTH.LOGIN.TITLE')}</h2>
        <p className="unautorized-sub-title">{t('AUTH.LOGIN.SUB_TITLE')}</p>
        <Outlet />
      </div>
    </div>
  );
};

export default UnauthorizedLayout;
