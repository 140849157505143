import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { SvgAlert } from '../../_assets/svg';
import { useAuthenticationContext } from '../../_context';
import { HttpClient, TApiError } from '../../_http';
import { TAuth, TProfile } from '../../profile/_models';
import { TLoginForm } from '../_models';

import './useLogin.scss';

function login(body: TLoginForm): Promise<TAuth> {
  return HttpClient.post<TAuth>('auth/signin', body);
}

export function useLogin() {
  const navigate = useNavigate();
  const { setProfile } = useAuthenticationContext();
  const { t } = useTranslation();

  const Msg = () => (
    <div className="toast">
      <div className="toast-header">
        <SvgAlert />
        <p className="toast-title">{t('AUTH.ERRORS.TITLE')}</p>
      </div>
      <p className="toast-body">{t('AUTH.ERRORS.UNAUTHORIZED')}</p>
    </div>
  );

  return useMutation<TAuth, TApiError, TLoginForm>('login', login, {
    onError: () => {
      toast.error(Msg, {
        closeButton: false,
        icon: false,
        style: {
          backgroundColor: '#FFF6F7',
          borderBottom: '1px solid #D83137',
          color: '#D83137',
          height: '116px',
        },
      });
    },
    onSuccess: async (data: TAuth) => {
      localStorage.setItem('access_token', JSON.stringify(data.access_token).slice(1, -1));
      const response = await HttpClient.get<TProfile>('users/me', {}, {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      });
      setProfile(response);
      navigate('/');
    },
  });
}
