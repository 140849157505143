import I18n from '../../_translations/I18n';

export function labelForUserState(userBlocked): string {
  switch (userBlocked) {
    case true:
      return I18n.t('USERS.STATE.OPTIONS.ACTIVE');
    case false:
      return I18n.t('USERS.STATE.OPTIONS.INACTIVE');
    default:
      return null;
  }
}
