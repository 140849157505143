import React from 'react';

import { SvgClock, SvgTerracedHouse, SvgWhite, SvgWhiteHouse } from '../../_assets/svg';

import './_card.scss';

// const Card = () => {
//   return (
//     <div className="card-container">
//       <SvgTerracedHouse className="sanfran-house" />
//       <div className="card_footer">
//         <div className="cardFooterHalf">
//           <h5>San Francisco</h5>
//           <span>20 Jun - 26 Jun</span>
//         </div>
//         <div className="cardFooterHalf ">
//           <p className="rightText">BRU - SFO</p>
//           <p>
//             <SvgClock className="clock" /> In 20 dagen!
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Card;
const data = [
  {
    dateFrom: '20 Jun',
    dateUntil: ' - 12 Jul',
    destination: 'San Francisco',
    flightFrom: 'BRU',
    flightTo: ' - SFO',
    holidayDuration: 'In 20 dagen!',
    svgComponent: <SvgTerracedHouse className="sanfran-house" />,
  },
  {
    dateFrom: '18 Sep',
    dateUntil: ' - 25 Nov',
    destination: 'Washington DC',
    flightFrom: 'CHA',
    flightTo: ' - DC',
    holidayDuration: 'In 68 dagen!',
    svgComponent: <SvgWhite className="washington-monument" />,
  },
];

const Card = ({ destination, dateFrom, dateUntil, flightFrom, flightTo, holidayDuration, svgComponent }) => (
  <div className="card-container">
    <div className='container'>{svgComponent}</div>
    <div className="card_footer">
      <div className="cardFooterHalf">
        <h5>{destination}</h5>
        <span>
          {dateFrom}
          {dateUntil}
        </span>
      </div>
      <div className="cardFooterHalf ">
        <p className="rightText">
          {flightFrom}
          {flightTo}
        </p>
        <p><SvgClock/> {holidayDuration}</p>
      </div>
    </div>
  </div>
);

const CardList = () => (
  <div className="displayCards">
    {data.map((item, index) => (
      <Card
        dateFrom={item.dateFrom}
        dateUntil={item.dateUntil}
        destination={item.destination}
        flightFrom={item.flightFrom}
        flightTo={item.flightTo}
        holidayDuration={item.holidayDuration}
        key={index}
        svgComponent={item.svgComponent}
      />
    ))}
  </div>
);

export default CardList;
