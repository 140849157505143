import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import type { TApiError } from '../../_http';
import { HttpClient } from '../../_http';
import I18n from '../../_translations/I18n';
import { removeEmptyKeys } from '../../_utils/objectHelpers';
import type { TRoleForm } from '../_models';

function createRole(body: TRoleForm): Promise<void> {
  return HttpClient.post('roles', removeEmptyKeys(body));
}

export function useCreateRole() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation<void, TApiError, TRoleForm>('createRole', createRole, {
    onSuccess: () => {
      toast.success(I18n.t('ROLES.TOASTER.ROLE_CREATED'));
      queryClient.invalidateQueries('getRoles');
      navigate('/roles');
    },
  });
}
