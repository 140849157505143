import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useForm } from '../../_hooks';
import type { TFormValidationErrors } from '../../_hooks/useForm';
import type { TApiError } from '../../_http';
import { Button, ErrorMessage, InputField } from '../../_shared';
import I18n from '../../_translations/I18n';
import { formValidator } from '../../_utils/formValidation';
import type { TChoosePasswordForm } from '../_models';
import { useChoosePassword } from '../_queries';

import './choosePassword.scss';

type TProps = {
  isPasswordReset?: boolean;
};

const initialForm: TChoosePasswordForm = {
  newPassword: '',
};

function validateForm(values: TChoosePasswordForm): TFormValidationErrors<TChoosePasswordForm> {
  return {
    newPassword: formValidator.password(values.newPassword),
  };
}

function errorAsString(error?: TApiError): string {
  if (error?.error === 'RESET_TOKEN_INVALID') return I18n.t('AUTH.ERRORS.RESET_TOKEN_INVALID');
  if (error?.error === 'RESET_TOKEN_EXPIRED') return I18n.t('AUTH.ERRORS.RESET_TOKEN_EXPIRED');
  return null;
}

const ChoosePassword: FC<TProps> = ({ isPasswordReset }) => {
  const { isLoading, error, mutate: choosePassword } = useChoosePassword();
  const { token } = useParams<{ token: string }>();
  const form = useForm<TChoosePasswordForm>({
    error,
    initialForm,
    submitForm: values => choosePassword({ ...values, resetToken: token }),
    validateForm,
  });
  const errorMessage = errorAsString(error);
  const { t } = useTranslation();

  return (
    <main className="right-container choose-password">
      <h1>{t(isPasswordReset ? 'AUTH.RESET_PASSWORD.TITLE' : 'AUTH.REGISTER.TITLE')}</h1>
      <p>{t('AUTH.PASSWORD_GUIDELINES')}</p>
      <form onSubmit={form.submit}>
        <ErrorMessage isGlobal isVisible={!!errorMessage}>
          {errorMessage}
        </ErrorMessage>
        <InputField
          autoComplete="new-password"
          name="newPassword"
          onChange={form.setAttribute}
          type="password"
          validation={form.validationErrors.newPassword}
          value={form.values.newPassword}
        />
        <div className="actions">
          <Button loading={isLoading} theme="primary" type="submit">
            {isPasswordReset ? t('AUTH.RESET_PASSWORD.RESET') : t('AUTH.REGISTER.REGISTER')}
          </Button>
        </div>
      </form>
    </main>
  );
};

export default ChoosePassword;
