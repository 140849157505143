import React, { FC, ReactNode } from 'react';
import classnames from 'classnames';

import Icon from '../icon/Icon';

import './errorMessage.scss';

type TProps = {
  children: ReactNode;
  className?: string;
  isGlobal?: boolean;
  isVisible: boolean;
};

const ErrorMessage: FC<TProps> = ({ className = '', children, isGlobal = false, isVisible }) => {
  if (isVisible && !!children)
    return (
      <div className={classnames('error-message', { global: isGlobal }, className)}>
        {isGlobal && <Icon name="SvgAlert" size={2.5} />}
        <span>{children}</span>
      </div>
    );
  return null;
};

export default ErrorMessage;
