import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthenticationContext } from '../../_context';
import type { TFillMetadataQueryFunction, THttpMetadataQuery } from '../../_http';
import { Button, Icon, SearchField } from '../../_shared';
import { useGetUsers } from '../_queries';
import UsersTable from './UsersTable';

import './usersOverview.scss';

const UsersOverview: FC = () => {
  const { profile } = useAuthenticationContext();
  const [usersQuery, setUsersQuery] = useState<THttpMetadataQuery>({});
  const { data, isLoading, fetchNextPage, refetch } = useGetUsers(usersQuery);
  const { t } = useTranslation();

  const allUsers = data?.pages.reduce((users, page) => [...users, ...page.data], []);
  const metadata = data?.pages[data.pages.length - 1].meta;

  const setQuery: TFillMetadataQueryFunction = (partialQuery: THttpMetadataQuery) => {
    setUsersQuery(value => ({ ...value, ...partialQuery }));
  };

  useEffect(() => {
    if (usersQuery?.skip !== 0) {
      fetchNextPage();
    } else {
      refetch();
    }
  }, [fetchNextPage, refetch, usersQuery]);

  return (
    <main className="container users">
      <div className="header">
        <SearchField query={usersQuery} setQuery={setQuery} />
        <Button href="create" theme="primary">
          <Icon name="SvgAdd" />
          <span>{t('USERS.OVERVIEW.CREATE_USER')}</span>
        </Button>
      </div>
      <UsersTable data={allUsers} isLoading={isLoading} metadata={metadata} setQuery={setQuery} />
    </main>
  );
};

export default UsersOverview;
