import React from 'react'

const PreviousTrips = () => {
  return (
    <div>
      Vorige Reizen ✈👨‍✈️🔙
    </div>
  )
}

export default PreviousTrips
