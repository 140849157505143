import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../_shared';
import type { TRoleForm } from '../_models';
import { useCreateRole } from '../_queries';
import RoleForm from '../edit/RoleForm';

const initialForm: TRoleForm = {
  name: '',
  permissions: {
    roles: { edit: false, view: false },
    users: { edit: false, view: false },
  },
};

const CreateRole: FC = () => {
  const { isLoading, error, mutate: createRole } = useCreateRole();
  const { t } = useTranslation();

  return (
    <main className="left-container">
      <header>
        <h1>{t('ROLES.CREATE.TITLE')}</h1>
      </header>
      <section>
        <RoleForm
          buttons={<Button href="/roles">{t('SHARED.BUTTONS.CANCEL')}</Button>}
          error={error}
          initialForm={initialForm}
          isSubmitting={isLoading}
          submitForm={(values: TRoleForm) => createRole(values)}
        />
      </section>
    </main>
  );
};

export default CreateRole;
