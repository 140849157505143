import { useState } from 'react';

import { THttpSortDirection } from '../_http';

export type TSortFunctions = {
  getSortDirectionForColumn: (column: string) => THttpSortDirection;
  onChangeSortColumn: (column: string) => void;
};

function sortDirectionOpposite(direction: string): THttpSortDirection {
  return direction === THttpSortDirection.Ascending ? THttpSortDirection.Descending : THttpSortDirection.Ascending;
}

type TResponse = {
  sortColumn?: string;
  sortDirection?: THttpSortDirection;
  sortFunctions: TSortFunctions;
};

const useTableSort = (
  callback: (column: string, direction: THttpSortDirection) => void,
  initialSortColumn: string = null,
): TResponse => {
  const [sortColumn, setSortColumn] = useState<string>(initialSortColumn);

  const [sortDirection, setSortDirection] = useState<THttpSortDirection>(THttpSortDirection.Ascending);

  const onChangeSortColumn = (column: string) => {
    const direction = sortColumn === column ? sortDirectionOpposite(sortDirection) : THttpSortDirection.Ascending;
    setSortColumn(column);
    setSortDirection(direction);
    callback(column, direction);
  };

  const getSortDirectionForColumn = (column: string) => (sortColumn === column ? sortDirection : null);

  return {
    sortColumn,
    sortDirection,
    sortFunctions: {
      getSortDirectionForColumn,
      onChangeSortColumn,
    },
  };
};

export default useTableSort;
