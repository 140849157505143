import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useForm } from '../../_hooks';
import type { TFormValidationErrors } from '../../_hooks/useForm';
import { Button, InputField } from '../../_shared';
import { formValidator } from '../../_utils/formValidation';
import type { TRequestPasswordResetForm } from '../_models';
import { useRequestPasswordReset } from '../_queries';

import './requestPasswordReset.scss';

const initialForm: TRequestPasswordResetForm = {
  email: '',
};

function validateForm(values: TRequestPasswordResetForm): TFormValidationErrors<TRequestPasswordResetForm> {
  return {
    email: formValidator.email(values.email),
  };
}

const RequestPasswordReset: FC = () => {
  const { isLoading, mutate: requestPasswordReset } = useRequestPasswordReset();
  const form = useForm<TRequestPasswordResetForm>({
    initialForm,
    submitForm: values => requestPasswordReset(values),
    validateForm,
  });
  const { t } = useTranslation();

  return (
    <main className="right-container request-password-reset">
      <h1>{t('AUTH.REQUEST_PASSWORD_RESET.TITLE')}</h1>
      <p>{t('AUTH.REQUEST_PASSWORD_RESET.DESCRIPTION')}</p>
      <form onSubmit={form.submit}>
        <InputField
          autoComplete="email"
          name="email"
          onChange={form.setAttribute}
          type="email"
          validation={form.validationErrors.email}
          value={form.values.email}
        />
        <div className="actions">
          <Button loading={isLoading} theme="primary" type="submit">
            {t('AUTH.REQUEST_PASSWORD_RESET.RESET')}
          </Button>
        </div>
      </form>
    </main>
  );
};

export default RequestPasswordReset;
