import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useForm } from '../../_hooks';
import type { TFormValidationErrors, TSubmitFormFunction } from '../../_hooks/useForm';
import type { TApiError } from '../../_http';
import { Button, ErrorMessage, InputField } from '../../_shared';
import I18n from '../../_translations/I18n';
import { formValidator } from '../../_utils/formValidation';
import RolesDropdown from '../../roles/edit/RolesDropdown';
import type { TUserForm } from '../_models';

type TProps = {
  buttons?: JSX.Element | JSX.Element[];
  error?: TApiError;
  initialForm: TUserForm;
  isSubmitting?: boolean;
  submitForm: TSubmitFormFunction<TUserForm>;
  userId?: string;
};

type TUserFormErrors = TUserForm & {
  roleIds?: string;
};

function errorAsString(error?: TApiError): string {
  if (error?.error === 'EMAIL_ALREADY_IN_USE') return I18n.t('USERS.ERRORS.EMAIL_ALREADY_IN_USE');
  if (error?.error === 'PERMISSION_DENIED') return I18n.t('ERRORS.PERMISSION_DENIED');
  return null;
}

const UserForm: FC<TProps> = ({ userId, initialForm, submitForm, isSubmitting, error, buttons }) => {
  function validateForm(values: TUserForm): TFormValidationErrors<TUserFormErrors> {
    const validation: TFormValidationErrors<TUserFormErrors> = {};
    if (values.email) validation.email = formValidator.email(values.email);
    else if (!userId) validation.email = formValidator.required(values.email);
    return validation;
  }

  const form = useForm<TUserForm, TUserFormErrors>({ error, initialForm, submitForm, validateForm });
  const errorMessage = errorAsString(error);
  const { t } = useTranslation();

  return (
    <form onSubmit={form.submit}>
      <ErrorMessage isGlobal isVisible={!!errorMessage}>
        {errorMessage}
      </ErrorMessage>
      {!userId && (
        <div role="group">
          <InputField
            label={t('USERS.EMAIL')}
            name="email"
            onChange={form.setAttribute}
            required
            type="email"
            validation={form.validationErrors.email}
            value={form.values.email}
          />
          <div />
        </div>
      )}
      <div role="group">
        <InputField
          label={t('USERS.FIRST_NAME')}
          name="firstName"
          onChange={form.setAttribute}
          type="text"
          validation={form.validationErrors.firstName}
          value={form.values.firstName}
        />
        <InputField
          label={t('USERS.LAST_NAME')}
          name="lastName"
          onChange={form.setAttribute}
          type="text"
          validation={form.validationErrors.lastName}
          value={form.values.lastName}
        />
      </div>
      <div className="actions">
        {buttons}
        <Button loading={isSubmitting} theme="primary" type="submit">
          {t(userId ? 'SHARED.BUTTONS.SAVE' : 'SHARED.BUTTONS.CREATE')}
        </Button>
      </div>
    </form>
  );
};

export default UserForm;
