import React from 'react'
import { useTranslation } from 'react-i18next';

import Card from '../card/Card'

const ComingTrips = () => {
  const { t } = useTranslation();
  return (
    <div>
       <div className="card">
        <span className="textAboveCard">{t('AUTHORIZEDLAYOUT.ABOVE_CARDS_TEXT')}</span> 
         {/* <CardList /> */}
      </div>
      <Card/>
    </div>
  )
}

export default ComingTrips
