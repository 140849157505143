import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useAuthenticationContext } from './_context';
import AuthorizedLayout from './_routing/authorized-layout/AuthorizedLayout';
import UnauthorizedLayout from './_routing/unauthorized-layout/UnauthorizedLayout';
import AuthRoutes from './auth/AuthRoutes';
import ModalWrapper from './modal/ModalWrapper';
import Profile from './profile/Profile';
import RolesRoutes from './roles/RolesRoutes';
import Toastify from './Toastify';
import UsersRoutes from './users/UsersRoutes';

const App: FC = () => {
  const { isLoggedIn } = useAuthenticationContext();

  return (
    <>
      <Routes>
        {isLoggedIn ? (
          <Route element={<AuthorizedLayout />} path="/*">
            <Route element={<Profile />} path="profile" />
            <Route element={<RolesRoutes />} path="roles/*" />
            <Route element={<UsersRoutes />} path="users/*" />
            <Route element={<Navigate to="profile" />} path="*" />
          </Route>
        ) : (
          <Route element={<UnauthorizedLayout />} path="/*">
            <Route element={<AuthRoutes />} path="auth/*" />
            <Route element={<Navigate to="auth" />} path="*" />
          </Route>
        )}
      </Routes>

      <ModalWrapper />
      <Toastify />
    </>
  );
};

export default App;
