import { useQuery } from 'react-query';

import type { TApiError } from '../../_http';
import { HttpClient } from '../../_http';
import type { TRole } from '../_models';

function getRoleDetail(roleId: string): Promise<TRole> {
  return HttpClient.get<TRole>(`roles/${roleId}`);
}

export function useGetRoleDetail(roleId: string) {
  return useQuery<TRole, TApiError>('getRoleDetail', () => getRoleDetail(roleId));
}
