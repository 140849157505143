import type { UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';

import type { TApiError } from '../../_http';
import { HttpClient } from '../../_http';
import type { TProfile } from '../../profile/_models';

function authenticate(): Promise<TProfile> {
  return HttpClient.get('users/me', null, { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` });
}

export function useAuthenticate(options: UseQueryOptions<TProfile, TApiError>) {
  return useQuery<TProfile, TApiError>('authenticate', authenticate, { ...options, retry: false });
}