import { useInfiniteQuery } from 'react-query';

import type { TApiError, THttpMetadataQuery, THttpPagedResponse } from '../../_http';
import { HttpClient } from '../../_http';
import { getQueryParams } from '../../_utils/queryHelpers';
import type { TUser } from '../_models';

function getUsers(query?: THttpMetadataQuery): Promise<THttpPagedResponse<TUser>> {
  return HttpClient.get<THttpPagedResponse<TUser>>(`users${getQueryParams(query)}`);
}

export function useGetUsers(query?: THttpMetadataQuery) {
  return useInfiniteQuery<THttpPagedResponse<TUser>, TApiError>('getUsers', () => getUsers(query), {
    getNextPageParam: lastPage => lastPage.meta,
  });
}
