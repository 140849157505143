import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDebounce } from '../../_hooks';
import type { TFillMetadataQueryFunction, THttpMetadataQuery } from '../../_http';
import Icon from '../icon/Icon';
import InputField from '../input/inputField/InputField';

import './searchField.scss';

type TProps = {
  query?: THttpMetadataQuery;
  setQuery: TFillMetadataQueryFunction;
};

function shouldSearch(currentQuery: THttpMetadataQuery, searchString: string): boolean {
  if (currentQuery?.search === searchString) return false;
  if (!currentQuery?.search && !searchString) return false;
  return true;
}

const SearchField: FC<TProps> = ({ query, setQuery }) => {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  const { t } = useTranslation();

  useEffect(() => {
    if (shouldSearch(query, debouncedSearch)) {
      setQuery({ search: debouncedSearch, skip: 0 });
    }
  }, [debouncedSearch, query, setQuery]);

  return (
    <div className="search-field-wrapper">
      <InputField
        className="search-field"
        icon={<Icon className="search-field-icon" name={search ? 'SvgClose' : 'SvgSearch'} onClick={() => setSearch('')} />}
        name="search"
        onChange={(value: string) => setSearch(value)}
        placeholder={t('SHARED.PLACEHOLDER.SEARCH')}
        value={search}
      />
    </div>
  );
};

export default SearchField;
