import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import CardList from '../../../src/components/card/Card';
import { SvgConnectionsLogo } from '../../_assets/svg';
import banner from '../../_assets/svg/profile_banner.jpg';
import { Menu } from '../../components/tabs/Tabs';

import './authorizedLayout.scss';
import './menu.scss';

const AuthorizedLayout: FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="authorized-layout">
        <div className="first">
          <SvgConnectionsLogo className="logo" />
          <img alt="Banner" className="banner" src={banner} />
          <p className="banner_text">{t('AUTHORIZEDLAYOUT.BANNER_TEXT')}</p>
        </div>
        <Outlet />
      </div>
      <div className="menu">
        <Menu />
      </div>
      <div className="card">
        {/* <span className="textAboveCard">{t('AUTHORIZEDLAYOUT.ABOVE_CARDS_TEXT')}</span>  */}
        {/* <CardList /> */}
      </div>
    </>
  );
};

export default AuthorizedLayout;
