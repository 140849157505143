import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import type { TApiError } from '../../_http';
import { HttpClient } from '../../_http';
import I18n from '../../_translations/I18n';

function deleteRole(roleId: string): Promise<void> {
  return HttpClient.delete(`roles/${roleId}`);
}

export function useDeleteRole() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation<void, TApiError, string>('deleteRole', deleteRole, {
    onSuccess: () => {
      toast.success(I18n.t('ROLES.TOASTER.ROLE_DELETED'));
      queryClient.invalidateQueries('getRoles');
      navigate('/roles');
    },
  });
}
