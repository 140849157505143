import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import type { TApiError } from '../../_http';
import { HttpClient } from '../../_http';
import I18n from '../../_translations/I18n';
import { removeEmptyKeys } from '../../_utils/objectHelpers';
import type { TUserForm } from '../_models';

function createUser(body: TUserForm): Promise<void> {
  return HttpClient.post('users', removeEmptyKeys(body));
}

export function useCreateUser() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation<void, TApiError, TUserForm>('createUser', createUser, {
    onSuccess: () => {
      toast.success(I18n.t('USERS.TOASTER.USER_CREATED'));
      queryClient.invalidateQueries('getUsers');
      navigate('/users');
    },
  });
}
