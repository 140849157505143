import React, { useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { SvgIconIcon, SvgListIcon, SvgSearch, SvgSearchIcon } from '../../_assets/svg';
import Alle from '../trips/AllTrips';
import KomendeReizen from '../trips/ComingTrips';
import Vorige from '../trips/PreviousTrips';

import './_react-tabs.scss';

export function Menu() {
  const [selectedOption, setSelectedOption] = useState<string>('all'); // set initial state of dropdown

  const handleOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(e.target.value); // update selected option when dropdown value changes
  }

  return (
    <Tabs className="my-tabs">
      <TabList className="tablist">
        <Tab>Komende reizen</Tab>
        <Tab>Vorige</Tab>
        <Tab>All</Tab>
        <div className="search-container">
          <input className="search-field" placeholder="Zoek op locatie, hotel, periode, personen" type="text" />
          <select className="FirstDropdown" value={selectedOption} onChange={handleOptionChange}>
            <option value="all">Filter op type</option>
            <option value="location">Locatie</option>
            <option value="hotel">Hotel</option>
            <option value="periode">Periode</option>
            <option value="personen">Personen</option>
          </select>
          <select className="SecondDropdown" value={selectedOption} onChange={handleOptionChange}>
            <option value="all">Sorteer op</option>
            <option value="location">Meest nabij</option>
            <option value="hotel">Nieuw - oud</option>
            <option value="periode">Oud - nieuw</option>
          </select>
        </div>
        <SvgIconIcon className="menuIconsFirst" />
        <SvgListIcon className="menuIconsSecond"/>
      </TabList>

      <TabPanel>
        <KomendeReizen />
      </TabPanel>
      <TabPanel>
        <Vorige />
      </TabPanel>
      <TabPanel>
        <Alle />
      </TabPanel>
    </Tabs>
  );
}
