import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import type { TApiError } from '../../_http';
import { HttpClient } from '../../_http';
import I18n from '../../_translations/I18n';

function deleteUser(userId: string): Promise<void> {
  return HttpClient.delete(`users/${userId}`);
}

export function useDeleteUser() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation<void, TApiError, string>('deleteUser', deleteUser, {
    onSuccess: () => {
      toast.success(I18n.t('USERS.TOASTER.USER_UPDATED'));
      queryClient.invalidateQueries('getUsers');
      navigate('/users');
    },
  });
}
