import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { SvgHide, SvgShow } from '../../_assets/svg';
import { useForm } from '../../_hooks';
import type { TFormValidationErrors } from '../../_hooks/useForm';
import type { TApiError } from '../../_http';
import { HttpStatus } from '../../_http';
import { Button, ErrorMessage, InputField } from '../../_shared';
import I18n from '../../_translations/I18n';
import { formValidator } from '../../_utils/formValidation';
import type { TLoginForm } from '../_models';
import { useLogin } from '../_queries';

import './login.scss';

const initialForm: TLoginForm = {
  email: '',
  password: '',
};

function validateForm(values: TLoginForm): TFormValidationErrors<TLoginForm> {
  return {
    email: formValidator.email(values.email),
    password: formValidator.required(values.password),
  };
}

function errorAsString(error?: TApiError): string {
  if (error?.statusCode === HttpStatus.Unauthorized) return I18n.t('AUTH.ERRORS.UNAUTHORIZED');
  if (error?.error === 'USER_STATE_NOT_ALLOWED') return I18n.t('AUTH.ERRORS.USER_STATE_NOT_ALLOWED');
  return null;
}

const Login: FC = () => {
  const { isLoading, error, mutate: login } = useLogin();
  const form = useForm<TLoginForm>({
    error,
    initialForm,
    submitForm: values => login(values),
    validateForm,
  });
  const errorMessage = errorAsString(error);
  const { t } = useTranslation();

  let theme = false;
  if (form.values.email !== '' && form.values.password !== '') theme = true;

  const [passwordShown, setPasswordShown] = useState(false);
  
  return (
    <main className="right-container login">
      <form onSubmit={form.submit}>
        <ErrorMessage isGlobal isVisible={!!errorMessage}>
          {errorMessage}
        </ErrorMessage>
        <InputField
          autoComplete="email"
          label={t('AUTH.LOGIN.USERNAME')}
          name="email"
          onChange={form.setAttribute}
          type="email"
          validation={form.validationErrors.email}
          value={form.values.email}
        />
        <InputField
          autoComplete="current-password"
          icon={
            passwordShown ? (
              <SvgShow onClick={() => setPasswordShown(!passwordShown)} />
            ) : (
              <SvgHide onClick={() => setPasswordShown(!passwordShown)} />
            )
          }
          label={t('AUTH.LOGIN.PASSWORD')}
          name="password"
          onChange={form.setAttribute}
          type={passwordShown ? 'text' : 'password'}
          validation={form.validationErrors.password}
          value={form.values.password}
        />
        <div className="actions">
          <Link className="link" to="">
            {t('AUTH.LOGIN.FORGOT_PASSWORD')}
          </Link>
        </div>
        <Button active={theme} disabled={!theme} loading={isLoading} type="submit">
          {t('AUTH.LOGIN.LOGIN')}
        </Button>
      </form>
    </main>
  );
};

export default Login;
