import type { TProfile } from '../../profile/_models';
import type { TUser } from './User';

export function canUserBeDeactiveated(user: TUser): boolean {
  return user.blocked;
}

export function canResendRegisterEmailForUser(user: TUser): boolean {
  return user.blocked;
}

export function canUserBeDeleted(user: TUser, profile: TProfile): boolean {
  return user.id !== profile.id;
}
