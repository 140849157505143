import React, { FC } from 'react';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './toastify.scss';

const Toastify: FC = () => {
  return (
    <ToastContainer
      autoClose={false}
      className={'toast-container'}
      closeOnClick
      draggable
      limit={1}
      newestOnTop={false}
      pauseOnFocusLoss
      position="top-center"
      rtl={false}
      theme="colored"
    />
  );
};

export default Toastify;
