import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import { useAuthenticationContext } from '../_context';
import CreateRole from './createRole/CreateRole';
import RoleDetail from './detail/RoleDetail';
import RolesOverview from './overview/RolesOverview';

const RolesRoutes: FC = () => {
  const { profile } = useAuthenticationContext();

  return (
    <Routes>
      <Route element={<RolesOverview />} path="*" />
        <Route element={<CreateRole />} path="create" />
      <Route element={<RoleDetail />} path=":id" />
    </Routes>
  );
};

export default RolesRoutes;
