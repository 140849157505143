import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import { AuthenticationContextProvider } from './_context';
import { Spinner } from './_shared';
import App from './App';
import { Config } from './config';
import * as serviceWorker from './serviceWorker';

import './index.scss';

// Setup sentry error logging
const environmentsWithErrorLogging = ['production', 'staging'];
const needsErrorLogging = Config.sentryDsn && environmentsWithErrorLogging.includes(Config.environment);
if (needsErrorLogging) {
  Sentry.init({
    dsn: Config.sentryDsn,
    environment: Config.environment,
  });
}

const queryClient = new QueryClient({ defaultOptions: Config.reactQuery });

createRoot(document.getElementById('root')).render(
  <Suspense fallback={<Spinner overlay />}>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AuthenticationContextProvider>
          <App />
        </AuthenticationContextProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </BrowserRouter>
  </Suspense>,
);

serviceWorker.unregister();
