import { useQuery } from 'react-query';

import type { TApiError } from '../../_http';
import { HttpClient } from '../../_http';
import type { TUser } from '../_models';

function getUserDetail(userId: string): Promise<TUser> {
  return HttpClient.get<TUser>(`users/${userId}`);
}

export function useGetUserDetail(userId: string) {
  return useQuery<TUser, TApiError>('getUserDetail', () => getUserDetail(userId));
}
