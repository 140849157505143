import { useEffect } from 'react';

import type { TValidatorResponse } from '../_utils/formValidation';
import useToggle from './useToggle';

type TResponse = {
  setDirty: () => void;
  showError: boolean;
};

export default function useInputError(validation?: TValidatorResponse): TResponse {
  const [isDirty, setIsDirty] = useToggle(false);

  useEffect(() => {
    setIsDirty(false);
  }, [validation, setIsDirty]);

  return {
    setDirty: () => setIsDirty(true),
    showError: !validation?.isValid && validation?.message && !isDirty,
  };
}
