import React, { FC } from 'react';
import classnames from 'classnames';

import { useInputError } from '../../../_hooks';
import Icon from '../../icon/Icon';
import type { TInputWrapperProps } from '../InputWrapper';
import InputWrapper from '../InputWrapper';

import './checkbox.scss';

export type TInputCheckboxProps = TInputWrapperProps & {
  checked?: boolean;
  label?: string;
  onChange: (checked: boolean, name: string) => void;
  type?: 'checkbox' | 'radio' | 'toggle';
};

const Checkbox: FC<TInputCheckboxProps> = ({ checked, label, onChange, type = 'checkbox', ...wrapperProps }) => {
  const { disabled, validation, name } = wrapperProps;
  const { setDirty, showError } = useInputError(validation);

  function renderCheckbox() {
    return <Icon label={name} name={checked ? 'SvgChecked' : 'SvgUnChecked'} />;
  }

  function renderRadioButton() {
    return <Icon label={name} name={checked ? 'SvgActiveRadio' : 'SvgInactiveRadio'} />;
  }

  function renderToggle() {
    return (
      <div className="switch">
        <input checked={checked} className="toggle" type="checkbox" />
        <span className="slider" />
      </div>
    );
  }

  function renderType() {
    switch (type) {
      case 'radio':
        return renderRadioButton();
      case 'toggle':
        return renderToggle();
      default:
        return renderCheckbox();
    }
  }

  return (
    <InputWrapper className={classnames('checkbox-wrapper', { 'is-disabled': disabled })} {...wrapperProps} showError={showError}>
      <div
        className={classnames({ 'is-checked': checked })}
        onClick={() => {
          onChange(!checked, name);
          setDirty();
        }}
      >
        {renderType()}
        <label className={classnames({ 'is-error': showError })}>{label}</label>
      </div>
    </InputWrapper>
  );
};

export default Checkbox;
